import { H5 } from '@cointracker/styleguide';
import { Grid } from '@cointracker/styleguide/src/Grid';
import { usePushFade } from '@cointracker/styleguide/src/hooks/usePushFade';
import classNames from 'classnames';
import { useRef } from 'react';
import alliePageUrl from '../../../assets/rebrand/case-study/allie-page.png?url';
import joshPettyUrl from '../../../assets/rebrand/case-study/josh-petty.png?url';
import {
  carouselContainer,
  container,
  containerOuter,
  title,
  topCopy,
} from './CaseStudyBlock.css';
import { CaseStudyCard } from './CaseStudyCard';

interface CaseStudyBlockProps {
  alignLeft?: boolean;
  animated?: boolean;
}

export const CaseStudyBlock = ({
  alignLeft = false,
  animated,
}: CaseStudyBlockProps) => {
  const ref = useRef<HTMLDivElement>(null);

  usePushFade(ref.current, { disabled: !animated });

  return (
    <div className={containerOuter} ref={ref}>
      <Grid>
        <div className={container}>
          <div className={topCopy({ alignLeft })}>
            <H5 className={classNames(title, { visible: true })}>
              {`Don't just take our word for it`}
            </H5>
          </div>
          <div className={carouselContainer} data-push-fade-item>
            <CaseStudyCard
              tag="Chief of Staff"
              author="Allie Page"
              title="The Blockchain Association"
              body={`"CoinTracker Enterprise has been a game changer and one-stop shop for our digital asset accounting. Onboarding was quick and painless. Support has been responsive and high quality. Overall, we've been impressed with the product's ability to categorize transactions quickly and accurately. Working with CoinTracker Enterprise has been a breeze, and I'd suggest it to anyone looking for a digital asset accounting solution."`}
              imageUrl={alliePageUrl}
            />
            <CaseStudyCard
              tag="Chief Executive Officer"
              author="Josh Petty"
              title="Ordinals Wallet"
              body={`"Our crypto accounting used to consume countless hours. CoinTracker Enterprise now handles 99% of the work automatically across our half-million transactions, empowering our team to pursue higher-value projects."`}
              imageUrl={joshPettyUrl}
            />
          </div>
        </div>
      </Grid>
    </div>
  );
};
