export default {
  v: '5.12.2',
  fr: 29.9700012207031,
  ip: 0,
  op: 256.000010427094,
  w: 1156,
  h: 1156,
  nm: '7. Get insights to grow',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'Graph',
      fr: 29.9700012207031,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 22,
                  s: [0],
                },
                { t: 28.0000011404634, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [598, 582, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [32, 32], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 2, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.92156869173, 1, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [89.66, -288.34], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 5,
          nm: '2024',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 22,
                  s: [0],
                },
                { t: 28.0000011404634, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [762.5, 852.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [2.771, -35.277, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 30,
                    f: 'CointrackerPro-Regular',
                    t: '2024',
                    ca: 0,
                    j: 0,
                    tr: 0,
                    lh: 36,
                    ls: 0,
                    fc: [1, 1, 1],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 5,
          nm: '2023',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 22,
                  s: [0],
                },
                { t: 28.0000011404634, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [492.5, 852.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [2.771, -35.277, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 30,
                    f: 'CointrackerPro-Regular',
                    t: '2023',
                    ca: 0,
                    j: 0,
                    tr: 0,
                    lh: 36,
                    ls: 0,
                    fc: [1, 1, 1],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 5,
          nm: '2022',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 22,
                  s: [0],
                },
                { t: 28.0000011404634, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [212.5, 852.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [2.771, -35.277, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 30,
                    f: 'CointrackerPro-Regular',
                    t: '2022',
                    ca: 0,
                    j: 0,
                    tr: 0,
                    lh: 36,
                    ls: 0,
                    fc: [1, 1, 1],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 5,
          nm: 'With CoinTracker',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 22,
                  s: [0],
                },
                { t: 28.0000011404634, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [724, 270, 0], ix: 2, l: 2 },
            a: { a: 0, k: [2.771, -35.277, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 30,
                    f: 'CointrackerPro-Regular',
                    t: 'With CoinTracker',
                    ca: 0,
                    j: 0,
                    tr: 0,
                    lh: 36,
                    ls: 0,
                    fc: [1, 1, 1],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 5,
          nm: 'Income ($)',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 22,
                  s: [0],
                },
                { t: 28.0000011404634, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [202, 270, 0], ix: 2, l: 2 },
            a: { a: 0, k: [2.771, -35.277, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 44,
                    f: 'CointrackerPro-Regular',
                    t: 'Income ($)',
                    ca: 0,
                    j: 0,
                    tr: 0,
                    lh: 52.8000030517578,
                    ls: 0,
                    fc: [1, 1, 1],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Mask 9',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1190, 578, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 99.067, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.539, -238.533],
                        [1.539, 243.131],
                        [-5.414, 243.131],
                        [-5.414, -238.533],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-305.211, 22.598], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 1,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 0,
          nm: 'Axis',
          tt: 1,
          tp: 7,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [881, 888, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 522, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 4,
          h: 522,
          ip: 5.00000020365417,
          op: 2185.00008899687,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Mask 8',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1122, 578, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 99.067, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.539, -238.533],
                        [1.539, 243.131],
                        [-5.414, 243.131],
                        [-5.414, -238.533],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-305.211, 22.598], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 1,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 0,
          nm: 'Axis',
          tt: 1,
          tp: 9,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [813, 888, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 522, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 4,
          h: 522,
          ip: 5.00000020365417,
          op: 2185.00008899687,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 0,
          nm: 'Axis',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [745, 888, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 522, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 4,
          h: 522,
          ip: 5.00000020365417,
          op: 2185.00008899687,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'Mask 7',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [986, 578, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 99.067, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.539, -238.533],
                        [1.539, 243.131],
                        [-5.414, 243.131],
                        [-5.414, -238.533],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-305.211, 22.598], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 1,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 0,
          nm: 'Axis',
          tt: 1,
          tp: 12,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [677, 888, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 522, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 4,
          h: 522,
          ip: 5.00000020365417,
          op: 2185.00008899687,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'Mask 6',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [918, 578, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 99.067, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.539, -238.533],
                        [1.539, 243.131],
                        [-5.414, 243.131],
                        [-5.414, -238.533],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-305.211, 22.598], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 1,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 0,
          nm: 'Axis',
          tt: 1,
          tp: 14,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [609, 888, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 522, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 4,
          h: 522,
          ip: 5.00000020365417,
          op: 2185.00008899687,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'Mask 5',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [850, 578, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 99.067, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.539, -238.533],
                        [1.539, 243.131],
                        [-5.414, 243.131],
                        [-5.414, -238.533],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-305.211, 22.598], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 1,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 0,
          nm: 'Axis',
          tt: 1,
          tp: 16,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [541, 888, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 522, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 4,
          h: 522,
          ip: 5.00000020365417,
          op: 2185.00008899687,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 0,
          nm: 'Axis',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [473, 888, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 522, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 4,
          h: 522,
          ip: 5.00000020365417,
          op: 2185.00008899687,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 19,
          ty: 4,
          nm: 'Mask 4',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [714, 578, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 99.067, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.539, -238.533],
                        [1.539, 243.131],
                        [-5.414, 243.131],
                        [-5.414, -238.533],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-305.211, 22.598], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 1,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 20,
          ty: 0,
          nm: 'Axis',
          tt: 1,
          tp: 19,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [405, 888, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 522, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 4,
          h: 522,
          ip: 5.00000020365417,
          op: 2185.00008899687,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 21,
          ty: 4,
          nm: 'Mask 3',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [646, 578, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 99.067, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.539, -238.533],
                        [1.539, 243.131],
                        [-5.414, 243.131],
                        [-5.414, -238.533],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-305.211, 22.598], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 1,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 22,
          ty: 0,
          nm: 'Axis',
          tt: 1,
          tp: 21,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [337, 888, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 522, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 4,
          h: 522,
          ip: 5.00000020365417,
          op: 2185.00008899687,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 23,
          ty: 4,
          nm: 'Mask 2',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [578, 578, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 99.067, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.539, -238.533],
                        [1.539, 243.131],
                        [-5.414, 243.131],
                        [-5.414, -238.533],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-305.211, 22.598], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 1,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 24,
          ty: 0,
          nm: 'Axis',
          tt: 1,
          tp: 23,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [269, 888, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 522, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 4,
          h: 522,
          ip: 5.00000020365417,
          op: 2185.00008899687,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 25,
          ty: 4,
          nm: 'Bar 22',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [890, 438, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -247.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.1, 0.1], y: [1, 1] },
                        o: { x: [0.05, 0.05], y: [0, 0.7] },
                        t: 18,
                        s: [54, 0],
                      },
                      { t: 28.0000011404634, s: [54, 222] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.921568632126, 1, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 26,
          ty: 4,
          nm: 'Bar 21',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [890, 722, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -143.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.8, 0.8], y: [1, 0.15] },
                        o: { x: [0.3, 0.3], y: [0, 0] },
                        t: 5,
                        s: [54, 0],
                      },
                      { t: 18.000000733155, s: [54, 172] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.61960786581, 0.662745118141, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 27,
          ty: 4,
          nm: 'Bar 20',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [822, 512, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -183.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.1, 0.1], y: [1, 1] },
                        o: { x: [0.05, 0.05], y: [0, 0.7] },
                        t: 18,
                        s: [54, 0],
                      },
                      { t: 28.0000011404634, s: [54, 158] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.921568632126, 1, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 28,
          ty: 4,
          nm: 'Bar 19',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [822, 722, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -143.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.8, 0.8], y: [1, 0.15] },
                        o: { x: [0.3, 0.3], y: [0, 0] },
                        t: 5,
                        s: [54, 0],
                      },
                      { t: 18.000000733155, s: [54, 162] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.61960786581, 0.662745118141, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 29,
          ty: 4,
          nm: 'Bar 18',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [754, 544, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -165.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.1, 0.1], y: [1, 1] },
                        o: { x: [0.05, 0.05], y: [0, 0.7] },
                        t: 18,
                        s: [54, 0],
                      },
                      { t: 28.0000011404634, s: [54, 140] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.921568632126, 1, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 30,
          ty: 4,
          nm: 'Bar 17',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [754, 722, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -143.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.8, 0.8], y: [1, 0.15] },
                        o: { x: [0.3, 0.3], y: [0, 0] },
                        t: 5,
                        s: [54, 0],
                      },
                      { t: 18.000000733155, s: [54, 148] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.61960786581, 0.662745118141, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 31,
          ty: 4,
          nm: 'Bar 16',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [686, 568, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -147.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.1, 0.1], y: [1, 1] },
                        o: { x: [0.05, 0.05], y: [0, 0.7] },
                        t: 18,
                        s: [54, 0],
                      },
                      { t: 28.0000011404634, s: [54, 122] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.921568632126, 1, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 32,
          ty: 4,
          nm: 'Bar 15',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [686, 722, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -143.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.8, 0.8], y: [1, 0.15] },
                        o: { x: [0.3, 0.3], y: [0, 0] },
                        t: 5,
                        s: [54, 0],
                      },
                      { t: 18.000000733155, s: [54, 142] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.61960786581, 0.662745118141, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 33,
          ty: 4,
          nm: 'Bar 14',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [618, 604, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -117.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.1, 0.1], y: [1, 1] },
                        o: { x: [0.05, 0.05], y: [0, 0.7] },
                        t: 18,
                        s: [54, 0],
                      },
                      { t: 28.0000011404634, s: [54, 92] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.921568632126, 1, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 34,
          ty: 4,
          nm: 'Bar 13',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [618, 722, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -143.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.8, 0.8], y: [1, 0.15] },
                        o: { x: [0.3, 0.3], y: [0, 0] },
                        t: 5,
                        s: [54, 0],
                      },
                      { t: 18.000000733155, s: [54, 136] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.61960786581, 0.662745118141, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 35,
          ty: 4,
          nm: 'Bar 12',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [550, 626, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -109.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.1, 0.1], y: [1, 1] },
                        o: { x: [0.05, 0.05], y: [0, 0.7] },
                        t: 18,
                        s: [54, 0],
                      },
                      { t: 28.0000011404634, s: [54, 84] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.921568632126, 1, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 36,
          ty: 4,
          nm: 'Bar 11',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [550, 722, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -143.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.8, 0.8], y: [1, 0.15] },
                        o: { x: [0.3, 0.3], y: [0, 0] },
                        t: 5,
                        s: [54, 0],
                      },
                      { t: 18.000000733155, s: [54, 122] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.61960786581, 0.662745118141, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 37,
          ty: 4,
          nm: 'Bar 10',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [482, 644, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -95.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.1, 0.1], y: [1, 1] },
                        o: { x: [0.05, 0.05], y: [0, 0.7] },
                        t: 18,
                        s: [54, 0],
                      },
                      { t: 28.0000011404634, s: [54, 70] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.921568632126, 1, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 38,
          ty: 4,
          nm: 'Bar 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [482, 722, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -143.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.8, 0.8], y: [1, 0.15] },
                        o: { x: [0.3, 0.3], y: [0, 0] },
                        t: 5,
                        s: [54, 0],
                      },
                      { t: 18.000000733155, s: [54, 118] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.61960786581, 0.662745118141, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 39,
          ty: 4,
          nm: 'Bar 8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [414, 666, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -87.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.1, 0.1], y: [1, 1] },
                        o: { x: [0.05, 0.05], y: [0, 0.7] },
                        t: 18,
                        s: [54, 0],
                      },
                      { t: 28.0000011404634, s: [54, 62] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.921568632126, 1, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 40,
          ty: 4,
          nm: 'Bar 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [414, 736, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -129.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.8, 0.8], y: [1, 0.15] },
                        o: { x: [0.3, 0.3], y: [0, 0] },
                        t: 5,
                        s: [54, 0],
                      },
                      { t: 18.000000733155, s: [54, 104] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.61960786581, 0.662745118141, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 41,
          ty: 4,
          nm: 'Bar 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [346, 686, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -83.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.1, 0.1], y: [1, 1] },
                        o: { x: [0.05, 0.05], y: [0, 0.7] },
                        t: 18,
                        s: [54, 0],
                      },
                      { t: 28.0000011404634, s: [54, 58] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.921568632126, 1, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 42,
          ty: 4,
          nm: 'Bar 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [346, 752, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -113.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.8, 0.8], y: [1, 0.15] },
                        o: { x: [0.3, 0.3], y: [0, 0] },
                        t: 5,
                        s: [54, 0],
                      },
                      { t: 18.000000733155, s: [54, 88] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.61960786581, 0.662745118141, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 43,
          ty: 4,
          nm: 'Bar 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [278, 698, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -81.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.1, 0.1], y: [1, 1] },
                        o: { x: [0.05, 0.05], y: [0, 0.7] },
                        t: 18,
                        s: [54, 0],
                      },
                      { t: 28.0000011404634, s: [54, 56] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.921568632126, 1, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 44,
          ty: 4,
          nm: 'Bar 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [278, 762, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -103.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.8, 0.8], y: [1, 0.15] },
                        o: { x: [0.3, 0.3], y: [0, 0] },
                        t: 5,
                        s: [54, 0],
                      },
                      { t: 18.000000733155, s: [54, 78] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.61960786581, 0.662745118141, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 45,
          ty: 4,
          nm: 'Bar 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [210, 710, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -77.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.1, 0.1], y: [1, 1] },
                        o: { x: [0.05, 0.05], y: [0, 0.7] },
                        t: 18,
                        s: [54, 0],
                      },
                      { t: 28.0000011404634, s: [54, 52] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.921568632126, 1, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 46,
          ty: 4,
          nm: 'Bar 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [210, 840, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-327.754, -25.387, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.8, 0.8], y: [1, 0.15] },
                        o: { x: [0.3, 0.3], y: [0, 0] },
                        t: 5,
                        s: [54, 0],
                      },
                      { t: 18.000000733155, s: [54, 70] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 8, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.61960786581, 0.662745118141, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-327.754, -25.387], ix: 2 },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x: "var $bm_rt;\nvar w, h;\nw = content('Rectangle 1').content('Rectangle Path 1').size[0];\nh = content('Rectangle 1').content('Rectangle Path 1').size[1];\n$bm_rt = [\n    w / -2,\n    h / 2\n];",
                  },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 47,
          ty: 4,
          nm: 'Cointainer 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [545.953, 549.648, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.1, 0.1], y: [1, 1] },
                        o: { x: [0.05, 0.05], y: [0.431, 0.471] },
                        t: 0,
                        s: [0, 0],
                      },
                      { t: 14.0000005702317, s: [910, 778] },
                    ],
                    ix: 2,
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 64, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.1254902035, 0.129411771894, 0.1254902035, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [32.047, 28.352], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1970.00008023974,
          st: -210.000008553475,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      nm: 'Axis',
      fr: 29.9700012207031,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [3.472, 286.007, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [2, 6], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-1.472, 232.993], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'rp',
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 0,
                    s: [0],
                  },
                  { t: 15.0000006109625, s: [44] },
                ],
                ix: 1,
              },
              o: { a: 0, k: 0, ix: 2 },
              m: 1,
              ix: 2,
              tr: {
                ty: 'tr',
                p: { a: 0, k: [0, -12], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 4 },
                so: { a: 0, k: 100, ix: 5 },
                eo: { a: 0, k: 100, ix: 6 },
                nm: 'Transform',
              },
              nm: 'Repeater 1',
              mn: 'ADBE Vector Filter - Repeater',
              hd: false,
            },
          ],
          ip: 0,
          op: 2180.00008879322,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
  ],
  fonts: {
    list: [
      {
        fName: 'CointrackerPro-Regular',
        fFamily: 'Cointracker Pro',
        fStyle: 'Regular',
        ascent: 70.3994750976562,
      },
    ],
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Graph',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [578, 578, 0], ix: 2, l: 2 },
        a: { a: 0, k: [578, 578, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100.71, 100.71, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1156,
      h: 1156,
      ip: 208.000008472014,
      op: 2388.00009726523,
      st: 208.000008472014,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 3,
      nm: 'Notification null',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [578, 578, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.8, 0.8, 0.8], y: [0.011, 0.011, 1] },
              o: { x: [0.3, 0.3, 0.3], y: [0, 0, 0] },
              t: 198,
              s: [100, 100, 100],
            },
            { t: 209.000008512745, s: [0, 0, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 87.0000035435826,
      op: 2149.00008753056,
      st: -31.0000012626559,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Shape Layer 2',
      parent: 2,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 96,
              s: [0],
            },
            { t: 103.000004195276, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, -134, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.871, -5.543],
                    [-7.76, -3.12],
                    [-29.751, 11.963],
                    [1.418, 9.02],
                    [3.192, 4.507],
                    [0.001, 6.935],
                    [27.706, 0],
                    [0, -26.802],
                    [4.181, -5.977],
                  ],
                  o: [
                    [-1.418, 9.02],
                    [29.752, 11.963],
                    [7.761, -3.12],
                    [-0.871, -5.543],
                    [-4.181, -5.977],
                    [0, -26.802],
                    [-27.706, 0],
                    [-0.001, 6.935],
                    [-3.192, 4.507],
                  ],
                  v: [
                    [-63.134, 15.96],
                    [-50.453, 34.361],
                    [50.453, 34.361],
                    [63.134, 15.96],
                    [54.763, 1.293],
                    [50.166, -18.138],
                    [0, -66.667],
                    [-50.166, -18.138],
                    [-54.763, 1.293],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-7.683, 0],
                    [-5.307, 4.146],
                  ],
                  o: [
                    [5.307, 4.146],
                    [7.683, 0],
                    [0, 0],
                  ],
                  v: [
                    [-20, 60.667],
                    [0, 67.333],
                    [20, 60.667],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 5, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 87.0000035435826,
      op: 2267.0000923368,
      st: 87.0000035435826,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 5,
      nm: 'You could earn $12,392/mo  by staking your ETH',
      parent: 2,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 96,
              s: [0],
            },
            { t: 103.000004195276, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [4.893, 32, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 40,
                f: 'CointrackerPro-Regular',
                t: 'You could earn $12,392/mo \rby staking your ETH',
                ca: 0,
                j: 2,
                tr: 0,
                lh: 48,
                ls: 0,
                fc: [1, 1, 1],
              },
              t: 0,
            },
          ],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 87.0000035435826,
      op: 2267.0000923368,
      st: 87.0000035435826,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 5,
      nm: 'Learn more',
      parent: 2,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 96,
              s: [0],
            },
            { t: 103.000004195276, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [4.893, 182, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 28,
                f: 'CointrackerPro-Regular',
                t: 'Learn more',
                ca: 0,
                j: 2,
                tr: 0,
                lh: 33.6000022888184,
                ls: 0,
                fc: [0.192, 0.192, 0.192],
              },
              t: 0,
            },
          ],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 87.0000035435826,
      op: 2267.0000923368,
      st: 87.0000035435826,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Shape Layer 3',
      parent: 2,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 96,
              s: [0],
            },
            { t: 103.000004195276, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [2.168, -48, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [228, 76], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 100, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-2.168, 220.617], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'fl',
          c: { a: 0, k: [0.921568632126, 1, 0, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        },
      ],
      ip: 87.0000035435826,
      op: 2267.0000923368,
      st: 87.0000035435826,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Cointainer 3',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-32.047, -28.352, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.1, 0.1], y: [1, 1] },
                    o: { x: [0.05, 0.05], y: [0.52, 0.542] },
                    t: 87,
                    s: [0, 0],
                  },
                  { t: 101.000004113814, s: [754, 676] },
                ],
                ix: 2,
              },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 64, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.1254902035, 0.129411771894, 0.1254902035, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [32.047, 28.352], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 87.0000035435826,
      op: 2057.00008378333,
      st: -123.000005009893,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 3,
      nm: 'Graph null',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [578, 578, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.8, 0.8, 0.8], y: [0.011, 0.011, 1] },
              o: { x: [0.3, 0.3, 0.3], y: [0, 0, 0] },
              t: 77,
              s: [100, 100, 100],
            },
            { t: 88.0000035843135, s: [0, 0, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: -31.0000012626559,
      op: 2149.00008753056,
      st: -31.0000012626559,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: 'Graph',
      parent: 8,
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
        a: { a: 0, k: [578, 578, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100.71, 100.71, 100], ix: 6, l: 2 },
      },
      ao: 0,
      tm: { a: 0, k: 1.902, ix: 2 },
      w: 1156,
      h: 1156,
      ip: 0,
      op: 2180.00008879322,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [604.617, 540.617, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [1156, 1156], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'gf',
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.263, 0.282, 0.275, 0.5, 0.202, 0.214, 0.208, 1, 0.141,
                    0.145, 0.141,
                  ],
                  ix: 9,
                },
              },
              s: { a: 0, k: [534.891, -650.563], ix: 5 },
              e: { a: 0, k: [-358.641, 578.672], ix: 6 },
              t: 1,
              nm: 'Gradient Fill 1',
              mn: 'ADBE Vector Graphic - G-Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-26.617, 37.383], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 2180.00008879322,
      st: 0,
      ct: 1,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
  chars: [
    {
      ch: 'I',
      size: 44,
      style: 'Regular',
      w: 24.8,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [8.258, 0],
                      [16.818, 0],
                      [17.322, -0.504],
                      [17.322, -70.395],
                      [16.818, -70.898],
                      [8.258, -70.898],
                      [7.755, -70.395],
                      [7.755, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'I',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'I',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'n',
      size: 44,
      style: 'Regular',
      w: 59.4,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-9.567, 0],
                      [0, -7.956],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [15.61, 0.101],
                      [1.611, -2.115],
                      [0.201, 0.504],
                      [0, 0],
                      [0.403, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -9.064],
                      [9.869, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -13.797],
                      [-6.546, -0.101],
                      [-0.403, 0.504],
                      [0, 0],
                      [-0.101, -0.504],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [8.057, 0],
                      [16.617, 0],
                      [17.12, -0.504],
                      [17.12, -28.198],
                      [30.515, -43.405],
                      [44.009, -27.695],
                      [44.009, -0.504],
                      [44.513, 0],
                      [53.073, 0],
                      [53.577, -0.504],
                      [53.577, -27.695],
                      [32.025, -51.965],
                      [18.228, -46.024],
                      [17.12, -46.124],
                      [16.113, -50.455],
                      [15.408, -50.958],
                      [8.057, -50.958],
                      [7.553, -50.455],
                      [7.553, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'n',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'n',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'c',
      size: 44,
      style: 'Regular',
      w: 54.4,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-15.811, 0],
                      [-1.007, 8.862],
                      [0.302, 0],
                      [0, 0],
                      [0.101, -0.302],
                      [7.553, 0],
                      [0, 10.474],
                      [-9.869, 0],
                      [-1.913, -6.042],
                      [-0.302, 0],
                      [0, 0],
                      [0.101, 0.403],
                      [14.2, 0],
                      [0, -15.811],
                    ],
                    o: [
                      [14.2, 0],
                      [0.101, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [-1.913, 6.042],
                      [-9.869, 0],
                      [0, -10.574],
                      [7.05, 0],
                      [0.101, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [-0.806, -8.459],
                      [-15.811, 0],
                      [0, 15.71],
                    ],
                    v: [
                      [28.299, 1.007],
                      [51.462, -16.919],
                      [50.958, -17.523],
                      [42.297, -17.523],
                      [41.693, -17.02],
                      [28.299, -7.352],
                      [13.092, -25.479],
                      [28.299, -43.607],
                      [41.592, -34.039],
                      [42.197, -33.536],
                      [50.858, -33.536],
                      [51.361, -34.14],
                      [28.299, -51.965],
                      [3.424, -25.479],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'c',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'c',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'o',
      size: 44,
      style: 'Regular',
      w: 56.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-15.71, 0],
                      [0, 15.71],
                      [15.71, 0],
                      [0, -15.811],
                    ],
                    o: [
                      [15.71, 0],
                      [0, -15.71],
                      [-15.71, 0],
                      [0, 15.71],
                    ],
                    v: [
                      [28.4, 1.007],
                      [53.476, -25.479],
                      [28.4, -52.066],
                      [3.424, -25.479],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'o',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 10.776],
                      [-9.769, 0],
                      [0, -10.776],
                      [9.869, 0],
                    ],
                    o: [
                      [0, -10.776],
                      [9.869, 0],
                      [0, 10.776],
                      [-9.769, 0],
                    ],
                    v: [
                      [12.991, -25.479],
                      [28.4, -43.405],
                      [43.909, -25.479],
                      [28.4, -7.553],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'o',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'o',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'm',
      size: 44,
      style: 'Regular',
      w: 87.2,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-8.862, 0],
                      [0, -7.654],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-9.164, 0],
                      [0, -7.553],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [13.495, 0],
                      [3.223, -4.633],
                      [0.302, 0.403],
                      [8.459, 0],
                      [1.41, -1.611],
                      [0.201, 0.504],
                      [0, 0],
                      [0.504, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -8.057],
                      [9.265, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -8.157],
                      [8.963, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -15.408],
                      [-7.05, 0],
                      [-0.302, 0.302],
                      [-2.216, -3.323],
                      [-6.747, 0],
                      [-0.302, 0.403],
                      [0, 0],
                      [-0.101, -0.504],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [8.057, 0],
                      [16.617, 0],
                      [17.12, -0.504],
                      [17.12, -28.4],
                      [28.4, -43.405],
                      [39.78, -28.4],
                      [39.78, -0.504],
                      [40.283, 0],
                      [48.843, 0],
                      [49.347, -0.504],
                      [49.347, -28.4],
                      [60.626, -43.506],
                      [72.006, -28.4],
                      [72.006, -0.504],
                      [72.51, 0],
                      [81.07, 0],
                      [81.573, -0.504],
                      [81.573, -28.702],
                      [61.633, -52.066],
                      [46.829, -44.815],
                      [45.822, -44.815],
                      [30.917, -51.965],
                      [18.127, -45.923],
                      [17.12, -46.124],
                      [16.113, -50.354],
                      [15.308, -50.958],
                      [8.057, -50.958],
                      [7.553, -50.455],
                      [7.553, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'm',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'm',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'e',
      size: 44,
      style: 'Regular',
      w: 55.3,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-14.703, 0],
                      [-2.719, 9.366],
                      [0.302, 0],
                      [0, 0],
                      [0.101, -0.302],
                      [6.244, 0],
                      [0, 7.15],
                      [-0.302, 0],
                      [0, 0],
                      [-0.101, 0.302],
                      [0, 1.309],
                      [14.905, 0],
                      [0, -15.811],
                    ],
                    o: [
                      [11.179, 0],
                      [0.101, -0.403],
                      [0, 0],
                      [-0.403, 0],
                      [-1.813, 3.625],
                      [-8.862, 0],
                      [0, -0.302],
                      [0, 0],
                      [0.403, 0],
                      [0.201, -0.604],
                      [0, -15.71],
                      [-14.905, 0],
                      [0, 15.71],
                    ],
                    v: [
                      [27.997, 1.007],
                      [50.656, -13.696],
                      [50.052, -14.301],
                      [40.988, -14.301],
                      [40.182, -13.797],
                      [27.997, -7.553],
                      [13.596, -21.35],
                      [14.099, -21.854],
                      [51.361, -21.854],
                      [51.965, -22.357],
                      [52.368, -25.479],
                      [27.997, -52.066],
                      [3.424, -25.479],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'e',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0.302],
                      [-9.869, 0],
                      [0, -5.64],
                      [0.302, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, -4.935],
                      [9.97, 0],
                      [0, 0.302],
                      [0, 0],
                      [-0.302, 0],
                    ],
                    v: [
                      [13.596, -30.414],
                      [27.997, -43.506],
                      [42.096, -30.414],
                      [41.592, -29.91],
                      [13.998, -29.91],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'e',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'e',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: ' ',
      size: 44,
      style: 'Regular',
      w: 22,
      data: {},
      fFamily: 'Cointracker Pro',
    },
    {
      ch: '(',
      size: 44,
      style: 'Regular',
      w: 29.8,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0.201, 0.302],
                      [0, 15.106],
                      [-7.251, 13.193],
                      [0.302, 0],
                      [0, 0],
                      [0.302, -0.504],
                      [0, -16.718],
                      [-7.05, -11.783],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [-7.251, -13.193],
                      [0, -15.005],
                      [0.201, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [-6.244, 10.373],
                      [0, 15.408],
                      [0.302, 0.403],
                    ],
                    v: [
                      [22.458, 19.94],
                      [29.105, 19.94],
                      [29.407, 19.336],
                      [16.415, -25.58],
                      [29.407, -70.294],
                      [29.105, -70.898],
                      [22.458, -70.898],
                      [21.552, -70.294],
                      [8.057, -25.58],
                      [21.552, 19.336],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '(',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '(',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: '$',
      size: 44,
      style: 'Regular',
      w: 61.8,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.201, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-0.403, 0],
                      [0, 12.286],
                      [10.474, 1.41],
                      [0, 0.403],
                      [0, 0],
                      [-0.403, -0.101],
                      [0, -5.539],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [10.373, 1.712],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [0.705, -0.101],
                      [0, -12.085],
                      [-12.085, -1.813],
                      [0, -0.302],
                      [0, 0],
                      [0.504, 0.101],
                      [0, 5.035],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [-14.905, -1.913],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.403],
                      [12.79, 0],
                      [0, -15.61],
                      [-0.604, -0.101],
                      [0, 0],
                      [0, -0.403],
                      [6.143, 1.309],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, -11.682],
                      [-0.504, -0.101],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.201, 0],
                      [0, 0],
                      [0, 0.302],
                      [-10.574, 1.208],
                      [0, 12.79],
                      [0.504, 0.101],
                      [0, 0],
                      [0, 0.302],
                      [-7.553, -1.41],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 9.366],
                      [0.604, 0.101],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [27.292, 10.071],
                      [33.838, 10.071],
                      [34.341, 9.567],
                      [34.341, 1.309],
                      [34.946, 0.705],
                      [58.008, -20.142],
                      [35.147, -40.585],
                      [34.341, -41.29],
                      [34.341, -62.137],
                      [35.046, -62.64],
                      [45.117, -51.462],
                      [45.621, -50.958],
                      [54.382, -50.958],
                      [54.886, -51.462],
                      [35.046, -71.503],
                      [34.341, -72.308],
                      [34.341, -80.264],
                      [33.838, -80.768],
                      [27.292, -80.768],
                      [26.889, -80.264],
                      [26.889, -72.308],
                      [26.184, -71.503],
                      [4.935, -51.663],
                      [26.285, -32.428],
                      [26.889, -31.723],
                      [26.889, -8.56],
                      [26.184, -8.057],
                      [13.998, -19.437],
                      [13.495, -19.94],
                      [4.733, -19.94],
                      [4.23, -19.437],
                      [26.184, 0.705],
                      [26.889, 1.41],
                      [26.889, 9.567],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '$',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 4.935],
                      [-6.445, 1.309],
                      [0, -0.302],
                      [0, 0],
                      [0.705, 0.101],
                    ],
                    o: [
                      [0, -5.74],
                      [0.504, -0.101],
                      [0, 0],
                      [0, 0.403],
                      [-8.157, -1.611],
                    ],
                    v: [
                      [14.804, -51.663],
                      [26.184, -63.043],
                      [26.889, -62.54],
                      [26.889, -42.297],
                      [25.983, -41.693],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '$',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 2,
                ty: 'sh',
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0.302],
                      [0, 0],
                      [-0.604, -0.101],
                      [0, -6.848],
                      [7.352, -1.208],
                    ],
                    o: [
                      [0, 0],
                      [0, -0.302],
                      [7.654, 1.309],
                      [0, 6.647],
                      [-0.504, 0.101],
                    ],
                    v: [
                      [34.341, -8.258],
                      [34.341, -30.917],
                      [35.147, -31.421],
                      [48.138, -20.142],
                      [35.147, -7.755],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '$',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '$',
            np: 6,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: ')',
      size: 44,
      style: 'Regular',
      w: 29.8,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [-0.302, 0.403],
                      [0, 15.408],
                      [6.244, 10.373],
                      [0.302, 0],
                      [0, 0],
                      [-0.201, -0.302],
                      [0, -15.005],
                      [7.352, -13.193],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [6.949, -11.783],
                      [0, -16.718],
                      [-0.302, -0.504],
                      [0, 0],
                      [-0.302, 0],
                      [7.352, 13.193],
                      [0, 15.106],
                      [-0.201, 0.302],
                    ],
                    v: [
                      [0.906, 19.94],
                      [7.654, 19.94],
                      [8.56, 19.336],
                      [21.954, -25.58],
                      [8.56, -70.294],
                      [7.654, -70.898],
                      [0.906, -70.898],
                      [0.604, -70.294],
                      [13.596, -25.58],
                      [0.604, 19.336],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: ')',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: ')',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'W',
      size: 30,
      style: 'Regular',
      w: 86.9,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [-0.101, 0.403],
                      [0, 0],
                      [-0.101, -0.604],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [-0.101, 0.403],
                      [0, 0],
                      [0.403, 0],
                      [0, 0],
                      [0.101, -0.403],
                      [0, 0],
                      [0.101, 0.604],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0.101, -0.403],
                      [0, 0],
                      [0.101, 0.604],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [-0.101, -0.403],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0.101, -0.604],
                      [0, 0],
                      [0.101, 0.403],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0.101, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [-0.101, 0.604],
                      [0, 0],
                      [-0.101, -0.403],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [-0.101, 0.604],
                      [0, 0],
                      [-0.101, -0.403],
                      [0, 0],
                      [-0.403, 0],
                      [0, 0],
                      [0.101, 0.403],
                    ],
                    v: [
                      [15.207, 0],
                      [30.011, 0],
                      [30.615, -0.604],
                      [43.204, -61.533],
                      [44.312, -61.533],
                      [56.9, -0.604],
                      [57.504, 0],
                      [72.409, 0],
                      [73.013, -0.604],
                      [86.911, -70.294],
                      [86.407, -70.898],
                      [77.142, -70.898],
                      [76.437, -70.395],
                      [65.46, -9.467],
                      [64.453, -9.467],
                      [52.167, -70.395],
                      [51.462, -70.898],
                      [35.953, -70.898],
                      [35.248, -70.395],
                      [23.062, -9.467],
                      [22.055, -9.467],
                      [11.078, -70.395],
                      [10.373, -70.898],
                      [1.108, -70.898],
                      [0.604, -70.294],
                      [14.502, -0.604],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'W',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'W',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'i',
      size: 30,
      style: 'Regular',
      w: 24.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [8.057, 0],
                      [16.617, 0],
                      [17.12, -0.504],
                      [17.12, -50.455],
                      [16.617, -50.958],
                      [8.057, -50.958],
                      [7.553, -50.455],
                      [7.553, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'i',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                    ],
                    o: [
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                    ],
                    v: [
                      [6.848, -60.223],
                      [7.352, -59.72],
                      [17.422, -59.72],
                      [17.926, -60.223],
                      [17.926, -70.395],
                      [17.422, -70.898],
                      [7.352, -70.898],
                      [6.848, -70.395],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'i',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'i',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 't',
      size: 30,
      style: 'Regular',
      w: 33.3,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-10.272, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, 3.625],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-3.223, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, 9.366],
                    ],
                    v: [
                      [23.264, 0],
                      [29.91, 0],
                      [30.414, -0.504],
                      [30.414, -8.057],
                      [29.91, -8.56],
                      [23.666, -8.56],
                      [18.53, -13.596],
                      [18.53, -42.197],
                      [19.034, -42.7],
                      [29.91, -42.7],
                      [30.414, -43.204],
                      [30.414, -50.455],
                      [29.91, -50.958],
                      [19.034, -50.958],
                      [18.53, -51.462],
                      [18.53, -64.755],
                      [18.027, -65.259],
                      [9.467, -65.259],
                      [8.963, -64.755],
                      [8.963, -51.462],
                      [8.459, -50.958],
                      [-0.504, -50.958],
                      [-1.007, -50.455],
                      [-1.007, -43.204],
                      [-0.504, -42.7],
                      [8.459, -42.7],
                      [8.963, -42.197],
                      [8.963, -13.495],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 't',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 't',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'h',
      size: 30,
      style: 'Regular',
      w: 59.4,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-9.567, 0],
                      [0, -7.452],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [15.106, 0],
                      [1.41, -1.41],
                      [0, 0.504],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -9.064],
                      [9.869, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -12.79],
                      [-7.553, 0],
                      [-0.403, 0.403],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [8.057, 0],
                      [16.617, 0],
                      [17.12, -0.504],
                      [17.12, -28.802],
                      [30.515, -43.204],
                      [44.009, -28.198],
                      [44.009, -0.504],
                      [44.513, 0],
                      [53.073, 0],
                      [53.577, -0.504],
                      [53.577, -28.198],
                      [31.522, -51.965],
                      [18.127, -46.426],
                      [17.12, -46.729],
                      [17.12, -70.395],
                      [16.617, -70.898],
                      [8.057, -70.898],
                      [7.553, -70.395],
                      [7.553, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'h',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'h',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'C',
      size: 30,
      style: 'Regular',
      w: 68.3,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-20.645, 0],
                      [-0.705, 4.935],
                      [0.302, 0],
                      [0, 0],
                      [0.101, -0.302],
                      [12.991, 0],
                      [0, 17.221],
                      [-13.898, 0],
                      [-1.208, -3.625],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [20.645, 0],
                      [0, -21.249],
                    ],
                    o: [
                      [21.249, 0],
                      [0, -0.403],
                      [0, 0],
                      [-0.302, 0],
                      [-1.108, 3.424],
                      [-13.898, 0],
                      [0, -17.221],
                      [12.891, 0],
                      [0.101, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [-0.705, -5.74],
                      [-20.645, 0],
                      [0, 21.35],
                    ],
                    v: [
                      [35.349, 1.007],
                      [64.151, -21.249],
                      [63.647, -21.854],
                      [54.886, -21.854],
                      [54.282, -21.35],
                      [35.349, -7.553],
                      [13.696, -35.147],
                      [35.349, -63.345],
                      [54.181, -49.649],
                      [54.886, -49.146],
                      [63.647, -49.146],
                      [64.151, -49.649],
                      [35.349, -71.906],
                      [3.726, -35.147],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'C',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'C',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'T',
      size: 30,
      style: 'Regular',
      w: 54.1,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [20.444, 0],
                      [29.004, 0],
                      [29.507, -0.504],
                      [29.507, -62.036],
                      [30.011, -62.54],
                      [49.448, -62.54],
                      [49.951, -63.043],
                      [49.951, -70.395],
                      [49.448, -70.898],
                      [0, -70.898],
                      [-0.504, -70.395],
                      [-0.504, -63.043],
                      [0, -62.54],
                      [19.437, -62.54],
                      [19.94, -62.036],
                      [19.94, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'T',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'T',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'r',
      size: 30,
      style: 'Regular',
      w: 37,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-9.567, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [2.115, -2.316],
                      [0.201, 0.504],
                      [0, 0],
                      [0.403, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -8.963],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-7.05, 0],
                      [-0.302, 0.302],
                      [0, 0],
                      [-0.101, -0.504],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [8.057, 0],
                      [16.617, 0],
                      [17.12, -0.504],
                      [17.12, -28.198],
                      [30.515, -42.7],
                      [35.953, -42.7],
                      [36.456, -43.204],
                      [36.456, -51.462],
                      [35.953, -51.965],
                      [31.018, -51.965],
                      [18.127, -45.923],
                      [17.12, -46.124],
                      [16.113, -50.455],
                      [15.308, -50.958],
                      [8.057, -50.958],
                      [7.553, -50.455],
                      [7.553, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'r',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'r',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'a',
      size: 30,
      style: 'Regular',
      w: 56.9,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-12.991, 0],
                      [-2.115, 2.014],
                      [-0.201, -0.504],
                      [-6.143, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, 3.122],
                      [0, 0],
                      [13.898, 0],
                      [0, -12.085],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [-8.862, 0],
                      [0, -3.625],
                      [2.618, 0],
                      [0, 0],
                      [0, -8.963],
                    ],
                    o: [
                      [10.272, 0],
                      [0.302, -0.302],
                      [1.611, 3.323],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-3.223, 0],
                      [0, 0],
                      [0, -12.387],
                      [-11.984, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, -5.64],
                      [12.085, 0],
                      [0, 2.618],
                      [0, 0],
                      [-14.099, 0],
                      [0, 9.164],
                    ],
                    v: [
                      [23.364, 1.007],
                      [40.283, -5.539],
                      [41.19, -5.438],
                      [51.361, 0],
                      [53.979, 0],
                      [54.483, -0.504],
                      [54.483, -7.654],
                      [53.979, -8.157],
                      [53.174, -8.157],
                      [49.045, -13.092],
                      [49.045, -33.334],
                      [26.99, -51.965],
                      [5.539, -34.039],
                      [6.042, -33.536],
                      [14.603, -33.536],
                      [15.106, -34.039],
                      [26.99, -43.405],
                      [39.478, -33.838],
                      [35.449, -30.615],
                      [24.976, -30.615],
                      [3.625, -14.905],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'a',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 3.424],
                      [-10.574, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [11.682, 0],
                    ],
                    o: [
                      [0, -3.525],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, 5.942],
                      [-7.553, 0],
                    ],
                    v: [
                      [13.193, -14.905],
                      [25.378, -22.559],
                      [38.974, -22.559],
                      [39.478, -22.055],
                      [39.478, -20.645],
                      [23.364, -7.553],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'a',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'a',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'k',
      size: 30,
      style: 'Regular',
      w: 55.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-0.504, 0.403],
                      [0, 0],
                      [-0.201, -0.302],
                      [0, 0],
                      [-0.403, 0],
                      [0, 0],
                      [0.201, 0.403],
                      [0, 0],
                      [-0.201, 0.201],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0.403, -0.403],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.604],
                      [0, 0],
                      [0.403, -0.403],
                      [0, 0],
                      [0.201, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [-0.201, -0.302],
                      [0, 0],
                      [0.302, -0.201],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [-0.403, 0.403],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [8.057, 0],
                      [16.617, 0],
                      [17.12, -0.504],
                      [17.12, -17.221],
                      [17.725, -18.329],
                      [28.802, -27.997],
                      [29.709, -27.997],
                      [45.721, -0.504],
                      [46.527, 0],
                      [55.591, 0],
                      [55.994, -0.705],
                      [36.758, -33.939],
                      [36.758, -34.644],
                      [54.987, -50.354],
                      [54.785, -50.958],
                      [42.297, -50.958],
                      [41.19, -50.455],
                      [17.825, -30.515],
                      [17.12, -30.716],
                      [17.12, -70.395],
                      [16.617, -70.898],
                      [8.057, -70.898],
                      [7.553, -70.395],
                      [7.553, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'k',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'k',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: '2',
      size: 30,
      style: 'Regular',
      w: 55.4,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [-0.302, 0.302],
                      [0, 0],
                      [0, 9.97],
                      [14.502, 0],
                      [0, -13.092],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [-7.352, 0],
                      [-0.101, -5.438],
                      [2.216, -2.115],
                      [0, 0],
                      [0, -0.504],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.403, 0],
                      [0, 0],
                      [4.129, -3.827],
                      [0, -11.984],
                      [-12.689, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, -7.05],
                      [9.467, 0],
                      [0.101, 6.143],
                      [0, 0],
                      [-0.403, 0.403],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [3.021, 0],
                      [48.541, 0],
                      [49.045, -0.504],
                      [49.045, -8.057],
                      [48.541, -8.56],
                      [16.415, -8.56],
                      [16.113, -9.467],
                      [38.37, -30.313],
                      [49.045, -50.656],
                      [26.083, -71.906],
                      [3.525, -50.656],
                      [4.028, -50.153],
                      [12.589, -50.153],
                      [13.092, -50.656],
                      [26.083, -63.345],
                      [39.478, -50.656],
                      [31.421, -36.658],
                      [3.021, -8.963],
                      [2.518, -7.755],
                      [2.518, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '2',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: '0',
      size: 30,
      style: 'Regular',
      w: 61.7,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-20.343, 0],
                      [0, 20.645],
                      [20.343, 0],
                      [0, -20.746],
                    ],
                    o: [
                      [20.343, 0],
                      [0, -20.746],
                      [-20.343, 0],
                      [0, 20.645],
                    ],
                    v: [
                      [32.529, 1.007],
                      [59.518, -35.449],
                      [32.529, -71.906],
                      [5.539, -35.449],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '0',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 17.221],
                      [-12.387, 0],
                      [0, -17.422],
                      [12.387, 0],
                    ],
                    o: [
                      [0, -17.422],
                      [12.387, 0],
                      [0, 17.221],
                      [-12.387, 0],
                    ],
                    v: [
                      [15.61, -35.449],
                      [32.529, -63.345],
                      [49.448, -35.449],
                      [32.529, -7.553],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '0',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '0',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: '3',
      size: 30,
      style: 'Regular',
      w: 52.7,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-14.703, 0],
                      [0, 10.876],
                      [3.424, 1.511],
                      [-0.504, 0.201],
                      [0, 9.064],
                      [13.293, 0],
                      [0, -13.293],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [-7.352, 0],
                      [0, -5.942],
                      [7.15, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, -8.258],
                      [8.359, 0],
                      [0, 6.042],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                    ],
                    o: [
                      [15.61, -0.101],
                      [0, -9.366],
                      [-0.504, -0.201],
                      [2.618, -1.309],
                      [0, -11.279],
                      [-12.589, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, -7.452],
                      [7.755, 0],
                      [0, 7.352],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [7.755, 0.101],
                      [0, 6.546],
                      [-8.963, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 11.783],
                    ],
                    v: [
                      [24.976, 1.007],
                      [48.642, -19.135],
                      [37.866, -35.852],
                      [37.866, -36.859],
                      [47.333, -51.562],
                      [24.976, -71.906],
                      [2.719, -50.656],
                      [3.223, -50.153],
                      [11.783, -50.153],
                      [12.286, -50.656],
                      [24.976, -63.345],
                      [37.866, -51.562],
                      [24.976, -40.485],
                      [20.947, -40.485],
                      [20.444, -39.981],
                      [20.444, -32.428],
                      [20.947, -31.924],
                      [24.976, -31.924],
                      [39.075, -19.135],
                      [24.976, -7.553],
                      [11.783, -20.041],
                      [11.279, -20.544],
                      [2.719, -20.544],
                      [2.216, -20.041],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '3',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '3',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: '4',
      size: 30,
      style: 'Regular',
      w: 60.3,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0.101, -0.302],
                      [0, 0],
                      [0, -0.504],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [-0.201, 0.403],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [35.349, 0],
                      [43.909, 0],
                      [44.412, -0.504],
                      [44.412, -15.71],
                      [44.916, -16.214],
                      [56.094, -16.214],
                      [56.598, -16.718],
                      [56.598, -24.271],
                      [56.094, -24.774],
                      [44.916, -24.774],
                      [44.412, -25.278],
                      [44.412, -70.395],
                      [43.909, -70.898],
                      [34.341, -70.898],
                      [33.636, -70.395],
                      [1.511, -25.278],
                      [1.108, -23.868],
                      [1.108, -16.718],
                      [1.611, -16.214],
                      [34.341, -16.214],
                      [34.845, -15.71],
                      [34.845, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '4',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0.504],
                      [0, 0],
                      [0, -0.504],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, -0.403],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-0.302, 0],
                    ],
                    v: [
                      [12.589, -25.58],
                      [33.838, -55.591],
                      [34.845, -55.289],
                      [34.845, -25.278],
                      [34.341, -24.774],
                      [12.891, -24.774],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '4',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '4',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'L',
      size: 28,
      style: 'Regular',
      w: 54.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [8.157, 0],
                      [53.174, 0],
                      [53.677, -0.504],
                      [53.677, -8.057],
                      [53.174, -8.56],
                      [17.725, -8.56],
                      [17.221, -9.064],
                      [17.221, -70.395],
                      [16.718, -70.898],
                      [8.157, -70.898],
                      [7.654, -70.395],
                      [7.654, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'L',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'L',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'Y',
      size: 40,
      style: 'Regular',
      w: 55.2,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-0.302, 0.504],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0.201, -0.403],
                      [0, 0],
                      [0.201, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [-0.201, -0.302],
                      [0, 0],
                      [0, -0.403],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.403],
                      [0, 0],
                      [0.201, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [-0.201, 0.302],
                      [0, 0],
                      [-0.201, -0.403],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0.302, 0.403],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [23.465, 0],
                      [32.025, 0],
                      [32.529, -0.504],
                      [32.529, -29.709],
                      [32.831, -31.018],
                      [56.699, -70.294],
                      [56.396, -70.898],
                      [46.426, -70.898],
                      [45.621, -70.395],
                      [28.098, -40.485],
                      [27.292, -40.485],
                      [9.97, -70.395],
                      [9.164, -70.898],
                      [-0.806, -70.898],
                      [-1.108, -70.294],
                      [22.659, -31.018],
                      [22.961, -29.709],
                      [22.961, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Y',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'Y',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'u',
      size: 40,
      style: 'Regular',
      w: 59.4,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-16.113, -0.101],
                      [-1.712, 2.216],
                      [-0.101, -0.504],
                      [0, 0],
                      [-0.403, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.201, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [9.567, 0],
                      [0, 7.956],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [6.546, 0],
                      [0.302, -0.403],
                      [0, 0],
                      [0.201, 0.504],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 7.956],
                      [-9.769, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 13.797],
                    ],
                    v: [
                      [28.299, 1.007],
                      [41.693, -5.035],
                      [42.7, -4.834],
                      [43.707, -0.604],
                      [44.513, 0],
                      [51.764, 0],
                      [52.267, -0.504],
                      [52.267, -50.455],
                      [51.865, -50.958],
                      [43.204, -50.958],
                      [42.7, -50.455],
                      [42.7, -22.76],
                      [29.306, -7.553],
                      [15.912, -23.264],
                      [15.912, -50.455],
                      [15.408, -50.958],
                      [6.848, -50.958],
                      [6.345, -50.455],
                      [6.345, -23.264],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'u',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'u',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'l',
      size: 40,
      style: 'Regular',
      w: 24.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [8.057, 0],
                      [16.617, 0],
                      [17.12, -0.504],
                      [17.12, -70.395],
                      [16.617, -70.898],
                      [8.057, -70.898],
                      [7.553, -70.395],
                      [7.553, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'l',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'l',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'd',
      size: 40,
      style: 'Regular',
      w: 59.6,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-15.005, 0],
                      [-1.511, 1.712],
                      [-0.101, -0.403],
                      [0, 0],
                      [-0.504, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [0.403, 0.504],
                      [8.762, 0],
                      [0, -15.71],
                    ],
                    o: [
                      [8.258, 0],
                      [0.403, -0.504],
                      [0, 0],
                      [0.101, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.504],
                      [-1.41, -1.611],
                      [-14.301, 0],
                      [0, 15.71],
                    ],
                    v: [
                      [26.99, 1.007],
                      [41.895, -4.935],
                      [42.902, -4.935],
                      [43.909, -0.504],
                      [44.714, 0],
                      [51.965, 0],
                      [52.469, -0.504],
                      [52.469, -70.395],
                      [51.965, -70.898],
                      [43.405, -70.898],
                      [42.902, -70.395],
                      [42.902, -46.124],
                      [41.995, -45.923],
                      [26.99, -51.965],
                      [3.424, -25.479],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'd',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 10.574],
                      [-9.97, 0],
                      [0, -11.078],
                      [9.668, 0],
                    ],
                    o: [
                      [0, -10.574],
                      [9.668, 0],
                      [0, 10.574],
                      [-9.97, 0],
                    ],
                    v: [
                      [12.991, -25.479],
                      [28.5, -43.405],
                      [42.902, -25.479],
                      [28.5, -7.553],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'd',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'd',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: '1',
      size: 40,
      style: 'Regular',
      w: 41,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [3.424, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, -1.813],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 3.223],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [1.813, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [21.149, 0],
                      [29.709, 0],
                      [30.212, -0.504],
                      [30.212, -70.395],
                      [29.709, -70.898],
                      [21.149, -70.898],
                      [20.645, -70.395],
                      [20.645, -63.245],
                      [14.502, -57.202],
                      [2.014, -57.202],
                      [1.511, -56.699],
                      [1.511, -49.649],
                      [2.014, -49.146],
                      [17.523, -49.146],
                      [20.645, -45.923],
                      [20.645, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: ',',
      size: 40,
      style: 'Regular',
      w: 27.1,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 8.459],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [4.532, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [9.567, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 4.532],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [7.352, 13.696],
                      [20.444, -0.403],
                      [20.444, -13.193],
                      [19.94, -13.696],
                      [7.352, -13.696],
                      [6.848, -13.193],
                      [6.848, -0.504],
                      [7.352, 0],
                      [13.092, 0],
                      [13.596, 0.504],
                      [7.352, 7.855],
                      [6.848, 8.359],
                      [6.848, 13.193],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: ',',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: ',',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: '9',
      size: 40,
      style: 'Regular',
      w: 55.7,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.403, 0],
                      [0, 0],
                      [-0.201, 0.403],
                      [0, 0],
                      [0, 5.338],
                      [15.71, 0],
                      [0, -14.603],
                      [-13.193, 0],
                      [-1.007, 0.504],
                      [0.302, -0.504],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [2.216, -4.028],
                      [0, -14.603],
                      [-15.71, 0],
                      [0, 14.905],
                      [2.719, 0],
                      [0.604, -0.201],
                      [0, 0],
                      [-0.201, 0.403],
                    ],
                    v: [
                      [22.659, 0],
                      [31.421, 0],
                      [32.227, -0.604],
                      [49.347, -31.622],
                      [53.174, -46.326],
                      [28.198, -71.402],
                      [3.122, -46.326],
                      [26.486, -21.35],
                      [32.932, -22.559],
                      [33.636, -21.753],
                      [22.156, -0.705],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '9',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 9.467],
                      [-10.172, 0],
                      [0, -9.567],
                      [10.172, 0],
                    ],
                    o: [
                      [0, -9.567],
                      [10.172, 0],
                      [0, 9.467],
                      [-10.172, 0],
                    ],
                    v: [
                      [12.991, -46.326],
                      [28.198, -62.842],
                      [43.304, -46.326],
                      [28.198, -29.709],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '9',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '9',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: '/',
      size: 40,
      style: 'Regular',
      w: 32.8,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.403, 0],
                      [0, 0],
                      [-0.101, 0.201],
                      [0, 0],
                      [0.403, 0],
                      [0, 0],
                      [0.101, -0.201],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.201, 0],
                      [0, 0],
                      [0.201, -0.403],
                      [0, 0],
                      [-0.201, 0],
                      [0, 0],
                      [-0.201, 0.403],
                    ],
                    v: [
                      [5.539, 0],
                      [14.703, 0],
                      [15.207, -0.302],
                      [49.548, -70.193],
                      [49.146, -70.898],
                      [39.981, -70.898],
                      [39.478, -70.596],
                      [5.136, -0.705],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '/',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '/',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    { ch: '\r', size: 40, style: 'Regular', w: 0, fFamily: 'Cointracker Pro' },
    {
      ch: 'b',
      size: 40,
      style: 'Regular',
      w: 59.6,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-8.258, 0],
                      [0, 15.71],
                      [14.2, 0],
                      [1.511, -1.611],
                      [0, 0.504],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [-0.201, 0.403],
                      [0, 0],
                      [-0.302, -0.504],
                    ],
                    o: [
                      [14.905, 0],
                      [0, -15.71],
                      [-8.862, 0],
                      [-0.302, 0.504],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.403, 0],
                      [0, 0],
                      [0.201, -0.403],
                      [1.611, 1.712],
                    ],
                    v: [
                      [33.133, 1.007],
                      [56.598, -25.479],
                      [33.133, -51.965],
                      [18.027, -45.923],
                      [17.12, -46.124],
                      [17.12, -70.395],
                      [16.617, -70.898],
                      [8.057, -70.898],
                      [7.553, -70.395],
                      [7.553, -0.504],
                      [8.057, 0],
                      [15.408, 0],
                      [16.214, -0.604],
                      [17.12, -4.935],
                      [18.127, -4.935],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'b',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 10.574],
                      [-9.668, 0],
                      [0, -10.574],
                      [9.869, 0],
                    ],
                    o: [
                      [0, -11.078],
                      [9.869, 0],
                      [0, 10.574],
                      [-9.668, 0],
                    ],
                    v: [
                      [17.12, -25.479],
                      [31.622, -43.405],
                      [47.031, -25.479],
                      [31.622, -7.553],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'b',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'b',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'y',
      size: 40,
      style: 'Regular',
      w: 48.3,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [-2.82, 8.459],
                      [0, 0],
                      [0.403, 0],
                      [0, 0],
                      [0.101, -0.403],
                      [0, 0],
                      [0.201, 0.504],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [-0.101, -0.302],
                      [0, 0],
                      [0.403, -1.712],
                      [3.928, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [8.762, 0],
                      [0, 0],
                      [0.101, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [-0.201, 0.504],
                      [0, 0],
                      [-0.101, -0.403],
                      [0, 0],
                      [-0.403, 0],
                      [0, 0],
                      [1.007, 3.223],
                      [-0.806, 3.122],
                      [0, 0],
                      [-0.403, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [0.806, 19.94],
                      [13.394, 19.94],
                      [28.299, 7.15],
                      [47.736, -50.354],
                      [47.232, -50.958],
                      [38.269, -50.958],
                      [37.564, -50.455],
                      [24.472, -8.963],
                      [23.465, -8.963],
                      [10.272, -50.455],
                      [9.668, -50.958],
                      [0.604, -50.958],
                      [0.101, -50.354],
                      [17.12, -0.302],
                      [17.926, 6.546],
                      [12.085, 11.682],
                      [0.806, 11.682],
                      [0.302, 12.186],
                      [0.302, 19.437],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'y',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'y',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 's',
      size: 40,
      style: 'Regular',
      w: 51.7,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-15.106, 0],
                      [0, 10.776],
                      [10.574, 1.108],
                      [0, 4.532],
                      [-7.15, 0],
                      [0, -5.539],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [12.488, 0],
                      [0, -8.459],
                      [-9.668, -0.906],
                      [0, -5.841],
                      [4.431, 0],
                      [0, 3.928],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                    ],
                    o: [
                      [13.696, 0],
                      [0, -13.293],
                      [-6.747, -0.705],
                      [0, -3.525],
                      [8.258, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, -12.085],
                      [-11.984, 0],
                      [0, 12.387],
                      [5.539, 0.604],
                      [0, 6.747],
                      [-9.769, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 9.366],
                    ],
                    v: [
                      [25.781, 1.007],
                      [48.138, -15.207],
                      [25.278, -30.615],
                      [13.696, -36.96],
                      [24.774, -43.405],
                      [37.262, -34.039],
                      [37.766, -33.536],
                      [46.426, -33.536],
                      [46.829, -34.039],
                      [24.774, -51.965],
                      [4.129, -36.96],
                      [25.781, -22.055],
                      [38.571, -15.207],
                      [25.781, -7.553],
                      [12.991, -17.02],
                      [12.488, -17.523],
                      [3.625, -17.523],
                      [3.122, -17.02],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 's',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 's',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'g',
      size: 40,
      style: 'Regular',
      w: 56,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-12.79, 0],
                      [0, 13.998],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0.201, -0.403],
                      [0, 0],
                      [0.302, 0.302],
                      [7.956, 0],
                      [0, -15.71],
                      [-13.394, 0],
                      [-1.913, 2.014],
                      [0, -0.403],
                      [0, 0],
                      [11.279, 0],
                      [0.705, 2.618],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                    ],
                    o: [
                      [14.301, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.403, 0],
                      [0, 0],
                      [-0.201, 0.403],
                      [-2.316, -2.316],
                      [-13.696, 0],
                      [0, 15.71],
                      [8.459, 0],
                      [0.302, -0.403],
                      [0, 0],
                      [0, 7.251],
                      [-8.459, 0],
                      [-0.101, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0.504, 8.359],
                    ],
                    v: [
                      [28.5, 20.947],
                      [52.67, -1.813],
                      [52.67, -50.455],
                      [52.167, -50.958],
                      [44.916, -50.958],
                      [44.11, -50.455],
                      [43.204, -46.124],
                      [42.297, -45.923],
                      [26.889, -51.965],
                      [3.424, -25.479],
                      [26.889, 1.007],
                      [42.297, -5.136],
                      [43.103, -4.834],
                      [43.103, -1.813],
                      [28.5, 12.387],
                      [15.408, 5.338],
                      [14.804, 4.834],
                      [6.445, 4.834],
                      [5.942, 5.338],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'g',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 10.574],
                      [-9.869, 0],
                      [0, -10.574],
                      [9.869, 0],
                    ],
                    o: [
                      [0, -10.574],
                      [9.869, 0],
                      [0, 11.078],
                      [-9.869, 0],
                    ],
                    v: [
                      [12.991, -25.479],
                      [28.4, -43.405],
                      [43.103, -25.479],
                      [28.4, -7.553],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'g',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'g',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'E',
      size: 40,
      style: 'Regular',
      w: 59.6,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [8.157, 0],
                      [56.497, 0],
                      [57.001, -0.504],
                      [57.001, -8.057],
                      [56.497, -8.56],
                      [17.725, -8.56],
                      [17.221, -9.064],
                      [17.221, -31.421],
                      [17.725, -31.924],
                      [51.764, -31.924],
                      [52.267, -32.428],
                      [52.267, -39.981],
                      [51.764, -40.485],
                      [17.725, -40.485],
                      [17.221, -40.988],
                      [17.221, -61.835],
                      [17.725, -62.338],
                      [56.497, -62.338],
                      [57.001, -62.842],
                      [57.001, -70.395],
                      [56.497, -70.898],
                      [8.157, -70.898],
                      [7.654, -70.395],
                      [7.654, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'E',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'E',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
    {
      ch: 'H',
      size: 40,
      style: 'Regular',
      w: 67.3,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, -0.302],
                      [0, 0],
                      [-0.302, 0],
                      [0, 0],
                      [0, 0.302],
                    ],
                    v: [
                      [8.157, 0],
                      [16.718, 0],
                      [17.221, -0.504],
                      [17.221, -31.421],
                      [17.725, -31.924],
                      [49.951, -31.924],
                      [50.455, -31.421],
                      [50.455, -0.504],
                      [50.958, 0],
                      [59.518, 0],
                      [60.022, -0.504],
                      [60.022, -70.395],
                      [59.518, -70.898],
                      [50.958, -70.898],
                      [50.455, -70.395],
                      [50.455, -40.988],
                      [49.951, -40.485],
                      [17.725, -40.485],
                      [17.221, -40.988],
                      [17.221, -70.395],
                      [16.718, -70.898],
                      [8.157, -70.898],
                      [7.654, -70.395],
                      [7.654, -0.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'H',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'H',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Cointracker Pro',
    },
  ],
};
