import {
  CallToActionButton,
  HeadingCTA,
} from '@/components/Rebrand/LandingCTAs';
import {
  Body2,
  CTABlockContainer,
  LandingCopyContainer,
  LandingHeaderContainer,
  LandingImage,
  LandingSubtitle,
  LandingTitle,
  PartnersLanding,
  SnapPointEnd,
} from '@cointracker/styleguide';
import {
  LandingCTASubtitle,
  LandingCTATitle,
} from '@cointracker/styleguide/src/CTABlock/CTABlock';
import { Grid } from '@cointracker/styleguide/src/Grid';
import { usePushFade } from '@cointracker/styleguide/src/hooks/usePushFade.js';
import AnchorageIcon from '@cointracker/styleguide/src/icons/enterprise/Anchorage.svg?react';
import BitgoIcon from '@cointracker/styleguide/src/icons/enterprise/Bitgo.svg?react';
import CoinbasePrimeIcon from '@cointracker/styleguide/src/icons/enterprise/Coinbase Prime.svg?react';
import FireblocksIcon from '@cointracker/styleguide/src/icons/enterprise/Fireblocks.svg?react';
import NetSuiteIcon from '@cointracker/styleguide/src/icons/enterprise/NetSuite.svg?react';
import QBOIcon from '@cointracker/styleguide/src/icons/enterprise/QBO.svg?react';
import XeroIcon from '@cointracker/styleguide/src/icons/enterprise/Xero.svg?react';
import alloraLabsUrl from '@cointracker/styleguide/src/icons/partners/Allora Labs.svg?url';
import blockchainAssociationUrl from '@cointracker/styleguide/src/icons/partners/Blockchain Association.svg?url';
import electricCoinCoUrl from '@cointracker/styleguide/src/icons/partners/Electric Coin Co.svg?url';
import ordinalsWalletUrl from '@cointracker/styleguide/src/icons/partners/Ordinals Wallet.svg?url';
import spritzFinanceUrl from '@cointracker/styleguide/src/icons/partners/Spritz Finance.svg?url';
import { COLORS } from '@cointracker/styleguide/src/themePrimitives.css';
import { REBRAND_URLS } from '@cointracker/ui';
import classNames from 'classnames';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useEffect, useRef } from 'react';
import headerUrl from '../../../assets/rebrand/enterprise-header-unoptimized.webp?url';
import comprehensiveCoverageUrl from '../../../assets/rebrand/lottie/5 - Integrate easily and flexibly.js';
import automateAtScaleUrl from '../../../assets/rebrand/lottie/6 - Automate at scale.js';
import getInsightsToGrowUrl from '../../../assets/rebrand/lottie/7 - Get insights to grow.js';
import alwaysAuditReadyUrl from '../../../assets/rebrand/lottie/8 - Always audit ready.js';
import BitcoinIcon from '../../../pages-react/landing/Hero/icons/bitcoin-coin.svg?react';
import EthereumIcon from '../../../pages-react/landing/Hero/icons/ethereum-coin.svg?react';
import { AppSnapPoint } from '../../AppSnapPoint';
import { CaseStudyBlock } from '../CaseStudyBlock';
import {
  LandingFeature,
  LandingFeatureButton,
  LandingFeatureContainer,
  LandingFeatureSubtitle,
  LandingFeatureTitle,
} from '../LandingFeature';
import { useFeatureState } from '../LandingFeature/utils';
import {
  landingHeaderContainer,
  landingHeaderCopyContainer,
  landingHeaderCopyContainerOuter,
  landingImageBlock,
  partnersContainer,
} from '../LandingFlow/LandingFlow.css';
import { useBackgroundFade } from '../LandingFlow/hooks/useBackgroundFade';

const FEATURES_SECTIONS = ['Complete', 'Ease', 'Accuracy', 'Growth'];

export const EnterpriseFlow = () => {
  const landingHeaderCopyRef = useRef<HTMLDivElement>(null);
  const landingImageRef = useRef<HTMLDivElement>(null);
  const partnersRef = useRef<HTMLDivElement>(null);
  const bottomCTARef = useRef<HTMLDivElement>(null);

  const { ref: comprehensiveCoverageRef, state: comprehensiveCoverageState } =
    useFeatureState();
  const { ref: automateAtScaleRef, state: automateAtScaleState } =
    useFeatureState();
  const { ref: alwaysAuditReadyRef, state: alwaysAuditReadyState } =
    useFeatureState();
  const { ref: getInsightsToGrowRef, state: getInsightsToGrowState } =
    useFeatureState();

  useBackgroundFade();

  usePushFade(bottomCTARef.current, {
    ease: 'cointrackerBounce',
    duration: 0.4,
    interval: 0.45,
    delay: 0.45,
  });

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    /// Landing copy
    const tlLandingHeader = gsap.timeline({
      scrollTrigger: {
        trigger: landingImageRef.current,
        start: 0,
        end: 300,
        scrub: true,
      },
    });
    tlLandingHeader.fromTo(
      landingHeaderCopyRef.current,
      {
        autoAlpha: 1,
      },
      {
        autoAlpha: 0,
      },
    );

    return () => {
      tlLandingHeader.kill();
    };
  }, []);

  return (
    <div>
      <AppSnapPoint topBarSpace></AppSnapPoint>
      <div className={landingHeaderContainer} ref={landingHeaderCopyRef}>
        <LandingHeaderContainer>
          <div className={landingHeaderCopyContainerOuter}>
            <Grid className={classNames(landingHeaderCopyContainer)}>
              <LandingCopyContainer className="[&&]:pt-160 sm:[&&]:pt-96">
                <LandingTitle>Accurate crypto</LandingTitle>
                <LandingTitle>accounting. Finally.</LandingTitle>
                <LandingSubtitle>
                  The subledger loved by the top teams in crypto.
                </LandingSubtitle>
                <div className="mt-24 flex flex-wrap justify-center gap-x-24 gap-y-8 py-8">
                  <div className="flex items-center gap-8">
                    <div className="flex">
                      <BitgoIcon className="h-30 w-30 rounded-full" />
                      <CoinbasePrimeIcon className="-ml-[6px] h-30 w-30 rounded-full" />
                      <AnchorageIcon className="-ml-[6px] h-30 w-30 rounded-full" />
                      <FireblocksIcon className="-ml-[6px] h-30 w-30 rounded-full" />
                      <BitcoinIcon className="-ml-[6px] h-30 w-30 rounded-full" />
                      <EthereumIcon className="-ml-[6px] h-30 w-30 rounded-full" />
                    </div>
                    <Body2 className="text-text-secondary">
                      500+ crypto&nbsp;integrations
                    </Body2>
                  </div>
                  <div className="flex items-center gap-8">
                    <div className="flex">
                      <QBOIcon className="h-30 w-30 rounded-full" />
                      <XeroIcon className="-ml-[6px] h-30 w-30 rounded-full" />
                      <NetSuiteIcon className="-ml-[6px] h-30 w-30 rounded-full" />
                    </div>
                    <Body2 className="text-text-secondary">
                      Sync directly to your ERP
                    </Body2>
                  </div>
                </div>
                <HeadingCTA
                  href={REBRAND_URLS.SCHEDULE_DEMO}
                  buttonText="Schedule demo"
                  page="enterprise landing"
                  name="hero"
                  title="Accurate crypto accounting. Finally."
                >
                  Schedule demo
                </HeadingCTA>
              </LandingCopyContainer>
            </Grid>
          </div>
        </LandingHeaderContainer>
      </div>

      <AppSnapPoint>
        <div ref={partnersRef}>
          <Grid className={landingImageBlock}>
            <div ref={landingImageRef}>
              <LandingImage imgSrc={headerUrl} imgAlt="placeholder" />
            </div>
          </Grid>
          <div className={partnersContainer}>
            <PartnersLanding
              title="Our customers"
              subtitle="We're trusted by some of the best in the business"
              topPartners={[
                { logoUrl: ordinalsWalletUrl, text: 'Ordinals Wallet' },
                {
                  logoUrl: blockchainAssociationUrl,
                  text: 'Blockchain Association',
                },
                { logoUrl: electricCoinCoUrl, text: 'Electric Coin Co.' },
                { logoUrl: spritzFinanceUrl, text: 'Spritz Finance' },
                { logoUrl: alloraLabsUrl, text: 'Allora Labs' },
              ]}
            />
          </div>
        </div>
      </AppSnapPoint>

      <AppSnapPoint>
        <CaseStudyBlock animated />
      </AppSnapPoint>

      <LandingFeatureContainer>
        <AppSnapPoint allowReset>
          <div ref={comprehensiveCoverageRef}>
            <LandingFeature
              sections={FEATURES_SECTIONS}
              sectionIndex={0}
              imgAlt="Comprehensive coverage"
              lottieData={comprehensiveCoverageUrl}
              snapState={comprehensiveCoverageState}
              isLottie
              noFadeIn
            >
              <LandingFeatureTitle>Comprehensive coverage</LandingFeatureTitle>
              <LandingFeatureSubtitle>
                Connect with 500+ wallets and exchanges including Anchorage,
                BitGo, Coinbase Prime, Fireblocks. Sync with QuickBooks,
                NetSuite, Sage, Xero, and others.
              </LandingFeatureSubtitle>
              <LandingFeatureButton href={REBRAND_URLS.SCHEDULE_DEMO}>
                See more
              </LandingFeatureButton>
            </LandingFeature>
          </div>
        </AppSnapPoint>
        <AppSnapPoint allowReset>
          <div ref={automateAtScaleRef}>
            <LandingFeature
              sections={FEATURES_SECTIONS}
              sectionIndex={1}
              imgAlt="Automate at scale"
              lottieData={automateAtScaleUrl}
              snapState={automateAtScaleState}
              isLottie
            >
              <LandingFeatureTitle>Automate at scale</LandingFeatureTitle>
              <LandingFeatureSubtitle>
                Support for 1 million crypto transactions per year. Automated
                categorization and account mapping saves you headaches and
                hassle.
              </LandingFeatureSubtitle>
              <LandingFeatureButton href={REBRAND_URLS.SCHEDULE_DEMO}>
                See more
              </LandingFeatureButton>
            </LandingFeature>
          </div>
        </AppSnapPoint>
        <AppSnapPoint allowReset>
          <div ref={alwaysAuditReadyRef}>
            <LandingFeature
              sections={FEATURES_SECTIONS}
              sectionIndex={2}
              imgAlt="Always audit ready"
              lottieData={alwaysAuditReadyUrl}
              snapState={alwaysAuditReadyState}
              isLottie
            >
              <LandingFeatureTitle>Always audit ready</LandingFeatureTitle>
              <LandingFeatureSubtitle>
                {`Our SOC 2 certified platform, accurate FMV and costs basis
              reporting mean we're not just incredibly fast, we're incredibly
              accurate.`}
              </LandingFeatureSubtitle>
              <LandingFeatureButton href={REBRAND_URLS.SCHEDULE_DEMO}>
                See more
              </LandingFeatureButton>
            </LandingFeature>
          </div>
        </AppSnapPoint>
        <AppSnapPoint allowReset>
          <div ref={getInsightsToGrowRef}>
            <LandingFeature
              sections={FEATURES_SECTIONS}
              sectionIndex={3}
              imgAlt="Get insights to grow"
              lottieData={getInsightsToGrowUrl}
              snapState={getInsightsToGrowState}
              isLottie
              noFadeOut
            >
              <LandingFeatureTitle>Get insights to grow</LandingFeatureTitle>
              <LandingFeatureSubtitle>
                Turn your account activity into actionable insights that could
                boost your crypto growth strategy.
              </LandingFeatureSubtitle>
              <LandingFeatureButton href={REBRAND_URLS.SCHEDULE_DEMO}>
                See more
              </LandingFeatureButton>
            </LandingFeature>
          </div>
        </AppSnapPoint>
      </LandingFeatureContainer>

      <AppSnapPoint>
        <div data-bg-color={COLORS.blue[60]}>
          <CTABlockContainer
            ref={bottomCTARef}
            className="[&&]:min-h-[650px]"
            noBackground
            fullscreen
          >
            <LandingCTATitle>
              <div className="[&&]:p-[0.05px]">
                <div>Save time.</div>
                <div data-push-fade-item>Save money.</div>
              </div>
            </LandingCTATitle>
            <div data-push-fade-item>
              <LandingCTASubtitle>
                Switch to the most accurate crypto subledger available.
              </LandingCTASubtitle>
              <div className="text-center">
                <CallToActionButton
                  href={REBRAND_URLS.SCHEDULE_DEMO}
                  buttonText="Get CoinTracker Enterprise"
                  pageTitle="enterprise landing"
                  sectionName="SaveTimeMoneyCTA"
                  sectionTitle="Save time. Save money."
                >
                  Get CoinTracker Enterprise
                </CallToActionButton>
              </div>
            </div>
          </CTABlockContainer>
        </div>
      </AppSnapPoint>

      <SnapPointEnd />
    </div>
  );
};
