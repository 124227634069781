import {
  Body2,
  Body3,
  CaseStudyContainer,
  CaseStudyContent,
  CaseStudyCopy,
  CaseStudyCopySection,
  H5,
} from '@cointracker/styleguide';

export const CaseStudyCard = ({
  title,
  body,
  tag,
  imageUrl,
  author,
}: {
  title: string;
  body: string;
  tag: string;
  imageUrl: string;
  author: string;
}) => (
  <CaseStudyContainer>
    <CaseStudyContent>
      <CaseStudyCopySection>
        <H5>{title}</H5>
        <CaseStudyCopy>{body}</CaseStudyCopy>
      </CaseStudyCopySection>
      <div className="flex w-full items-center gap-12 pt-32">
        <img src={imageUrl} alt={author} className="h-48 w-48" />
        <div className="flex flex-col gap-2">
          <Body2>{author}</Body2>
          <Body3 variant="secondary">{tag}</Body3>
        </div>
      </div>
    </CaseStudyContent>
  </CaseStudyContainer>
);
