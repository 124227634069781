import { trackSignUpButtonClicked } from '@/common/analytics';
import { LandingButton, type ButtonProps } from '@cointracker/styleguide';

interface HeadingCTAProps extends ButtonProps {
  href: string;
  buttonText: string;
  page: string;
  name: string;
  title: string;
}
export const HeadingCTA = ({
  href,
  buttonText,
  page,
  name,
  title,
  children,
  className,
  ...props
}: HeadingCTAProps) => {
  const trackSignup = () => {
    trackSignUpButtonClicked(buttonText, name, title, page);
    window.location.href = href;
  };
  return (
    <LandingButton className={className} onClick={trackSignup} {...props}>
      {children}
    </LandingButton>
  );
};
